<script>
	import SuperInput from '$lib/Components/Forms/SuperInput/SuperInput.svelte';
	import Button from '$lib/Components/Layout/Button/Button.svelte';
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import LeadForm from '$lib/PageSections/Forms/Lead/Lead.svelte';
	import postalCodeSchema from '$lib/PageSections/Forms/schemas/postalCode.schema.js';

	import { onMount } from 'svelte';
	import { superForm } from 'sveltekit-superforms';
	import { zodClient } from 'sveltekit-superforms/adapters';

	import globeImage from './images/globe.png';
	import installGuy from './images/guy-install-cropped.svg';

	export let data;
	export let form;
	export let focus = null;
	export let buttonText = 'LET US HELP YOU';
	export let buttonTextLead = 'CREATE COMFORT NOW';
	export let formAction = null;
	export let formActionLead = '/forms/lead';
	export let showMap = false;
	let locationLabel;

	let isServiceable = form?.location?.isServiceable;
	let leadFormData;
	const postalCodeForm = superForm(
		data?.forms?.postalCodeForm ?? zodClient(postalCodeSchema),
		{
			validators: zodClient(postalCodeSchema),
			taintedMessage: null,
			onSubmit() {
				previousZip = zipInput.value;
				loading = true;
				if (showMap) {
					mapLoad();
				}

				// set fallback timeout to show form after 6 seconds regardless of result
				timers.fallback = setTimeout(() => {
					clearTimeout(timers.zoomed);
					showForm = true;
				}, 6000);

				hideZipSearchButton = true;
			},
			onResult({ result }) {
				isServiceable = result?.data?.location?.isServiceable;
				loading = false;
				locationLabel = result?.data?.location?.Address?.Label;

				if (showMap) {
					// set timeout to show form after zoom effect is finished
					timers.zoomed = setTimeout(() => {
						clearTimeout(timers.fallback);
						showForm = true;
					}, 3000);
					mapZoomIn(result.data.location);
				} else {
					showForm = true;
				}
			},
			onError({ result }) {
				$message = result.error.message;
			},
			onUpdate({ form }) {
				if (form?.data?.postalCode) {
					$leadFormData.postalCode = form?.data?.postalCode;
					if (form?.data?.country) {
						$leadFormData.country = form?.data?.country;
					}
				}

				loading = false;
				if (!form.postalCode) {
					showForm = true;
				}
			},
			onUpdated() {
				if (locationLabel) {
					zipInput.value = locationLabel;
				}
			},
		},
	);
	const { message, enhance } = postalCodeForm;

	onMount(async () => {
		if (showMap) {
			mapLoad();
		}
		// TODO: dynamic loading of the lead form is more efficient, but it doesn't work if javascript is disabled/unavailable
		// LeadForm = commercial
		// 	? (
		// 			await import(
		// 				'$lib/PageSections/Forms/LeadCommercial/LeadCommercial.svelte'
		// 			)
		// 	  ).default
		// 	: (await import('$lib/PageSections/Forms/Lead/Lead.svelte')).default;
	});

	// postal code bounds to get a static map image when js is not available
	let bounds;
	if (showMap && form?.location?.MapView) {
		bounds = `${form?.location?.MapView?.TopLeft?.Longitude},${form?.location?.MapView?.BottomRight?.Latitude},${form?.location?.MapView?.BottomRight?.Longitude},${form?.location?.MapView?.TopLeft?.Latitude}`;
	}

	let Map; // we dynamically load the Map component to avoid a large initial payload

	// node binds
	let zipInput;
	let map;
	// export let debug = true;

	let loading = false;
	let hideZipSearchButton = false;
	let showForm = false;

	if (form?.postalCodeForm?.data?.postalCode != undefined) {
		showForm = true;
	}

	let previousZip;

	// keep track of map loaded state
	let mapLoaded = false;
	let mapLoadedResolve;
	let mapLoadedPromise = new Promise((resolve) => {
		mapLoadedResolve = resolve;
	}).then(() => {
		mapLoaded = true;
	});

	let timers = { zoomed: null, fallback: null };
	function mapClearTimers() {
		clearTimeout(timers.zoomed);
		clearTimeout(timers.fallback);
	}

	async function mapLoad() {
		if (!mapLoaded) {
			Map = await (await import('$lib/Components/Map/Map.svelte')).default;
			await mapLoadedPromise;
		}
	}

	async function mapZoomOut() {
		await mapLoad();
		map?.zoomOut();
	}

	async function mapZoomIn(location) {
		await mapLoad();
		map?.updateMap(location);
	}
</script>

<div
	class={[
		'full-width mb-4 grid w-full grid-cols-1 grid-rows-1',
		$$props.class ?? '',
	].join(' ')}
>
	<div
		class="background-transparent form-container col-span-full row-span-full flex h-full w-full max-w-xl flex-col justify-self-center"
	>
		<div class="flex w-full flex-col gap-4">
			<div class="rounded-lg bg-indow-white p-6 shadow-lg" class:mt-5={showMap}>
				<div>
					<form
						name="postal-code"
						method="POST"
						action={formAction ??
							(showMap
								? '/forms/postal-code-map?/postalCode'
								: '/forms/postal-code?/postalCode')}
						disabled={loading}
						use:enhance
						class="row-auto grid grid-cols-2 gap-x-4"
					>
						<SuperInput
							class="!mb-0"
							form={postalCodeForm}
							name="postalCode"
							type="search"
							label="Zip / Postal Code"
							autocomplete="postal-code"
							disabled={loading}
							tabindex="0"
							bind:loading
							bind:element={zipInput}
							on:input={() => {
								if (previousZip) {
									previousZip = null;
								}
							}}
							on:focus={async () => {
								if (previousZip) {
									zipInput.value = previousZip;
								}
								mapClearTimers();

								hideZipSearchButton = false;
								if (showForm) {
									showForm = false;
								}
								mapZoomOut();
							}}
						/>

						<div
							class="col-span-full grid"
							class:grid-rows-[0fr]={hideZipSearchButton}
							class:grid-rows-[1fr]={!hideZipSearchButton}
							style="transition: grid-template-rows 0.25s ease-out;"
						>
							<div
								class="grid overflow-hidden"
								class:!mt-4={!hideZipSearchButton}
								style="transition:  margin-top 0.25s ease-out;"
							>
								<Button
									class="mt-0 w-full px-0"
									buttonType="button"
									disabled={loading}>{buttonText}</Button
								>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div
			class="-m-4 grid"
			class:grid-rows-[0fr]={!showForm}
			class:grid-rows-[1fr]={showForm}
			style="transition: grid-template-rows 0.5s ease-out;"
		>
			<div
				class:pointer-events-none={!showForm}
				class:!opacity-100={showForm}
				class="overflow-hidden p-4 opacity-0"
				style="transition: opacity 0.5s ease-out;"
			>
				<div
					class="grid"
					class:grid-rows-[0fr]={!isServiceable ||
						!data?.forms?.location?.isServiceable}
					class:grid-rows-[1fr]={isServiceable ||
						data?.forms?.location?.isServiceable}
				>
					<div
						class:pointer-events-none={!isServiceable ||
							!data?.forms?.location?.isServiceable}
						class:!opacity-100={isServiceable ||
							data?.forms?.location?.isServiceable}
						class:!mb-4={isServiceable || data?.forms?.location?.isServiceable}
						class="overflow-hidden rounded-lg bg-indow-white opacity-0 shadow-lg"
						style="transition: opacity 0.5s ease-out;"
					>
						<div class="we-ship-to-you grid justify-items-center gap-x-4 p-6">
							<div class="col-start-1 flex flex-col justify-center">
								<h2
									class="px-0 text-[clamp(1.5rem,5vw,1.875rem)] !text-indow-blue"
								>
									WE SHIP TO YOU!
								</h2>
								<p
									class="px-0 text-[clamp(1rem,5vw,1.25rem)] font-bold leading-none !text-indow-grey-dark"
								>
									You're one step closer to creating more comfort & quiet in
									your home.
								</p>
							</div>

							<div class="install-guy">
								<Image
									width="131"
									height="150"
									src={installGuy}
									alt="Congratulations!"
									class="m-0 max-h-[7rem] w-auto min-w-[5rem] object-contain p-0"
								/>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div
						class="mb-5 grid justify-items-center gap-4 rounded-lg bg-indow-white p-6 shadow-lg"
					>
						<LeadForm
							{data}
							{focus}
							disabled={!showForm}
							buttonText={buttonTextLead}
							action={formActionLead}
							bind:leadFormData
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
	{#if showMap}
		<div class="-z-20 !col-span-full row-span-full min-h-[40rem]">
			<svelte:component
				this={Map}
				bind:this={map}
				on:mapLoaded={() => {
					mapLoadedResolve();
				}}
				on:mapZoomIn={() => {
					mapClearTimers();
					showForm = true;
				}}
			/>
		</div>
		<div
			class="-z-10 !col-span-full row-span-full grid max-h-[40rem] w-full bg-indow-white pb-[1.2rem] pt-[7.5rem] transition-opacity"
			class:opacity-0={mapLoaded}
		>
			<div class="h-full max-w-[31rem] place-self-center">
				<Image src={globeImage} loading="eager" fill class="h-full" />
			</div>
		</div>
		<noscript style="display: contents;">
			{#if bounds}
				<div
					class="-z-10 col-span-full row-span-full mb-[-1rem] grid w-full content-between bg-indow-white"
				>
					<img
						src={`/forms/postal-code-map/static-image/${bounds}`}
						loading="eager"
						alt="map"
						class="max-h-[40rem] w-full"
					/>
				</div>
			{/if}
		</noscript>
	{/if}
</div>

<style>
	.install-guy {
		display: none;
	}

	.form-container > *:not(:last-child) {
		margin-bottom: 1rem;
	}

	@media (min-width: 27rem) {
		.install-guy {
			display: block;
		}
		.we-ship-to-you {
			grid-template-columns: auto auto;
		}
	}
</style>
